import React, { useContext } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { AppContext } from "../../util/app-context";

import Map from "./Map";
import Form from "./Form";

import "./Contact.css";

const Contact = () => {
  const appCtx = useContext(AppContext);
  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
      <div className="contact-div" id="contact">
        <h2>{appCtx.data.header.nav.contact[appCtx.lang]}</h2>
        <div className="contact-content">
          <Form />
          <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
            <Map />
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default Contact;
