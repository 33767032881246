import React, { useContext } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { AppContext } from "../../util/app-context";

import CriminalLaw from "./Areas/CriminalLaw";
import CivilLaw from "./Areas/CivilLaw";
import LabourLaw from "./Areas/LabourLaw";
import TortLaw from "./Areas/TortLaw";
import CommercialLaw from "./Areas/CommercialLaw";
import AdministrativeLaw from "./Areas/AdministrativeLaw";
import InternationalLaw from "./Areas/InternationalLaw";

import "./Services.css";

const Services = () => {
  const appCtx = useContext(AppContext);

  return (
    <ScrollAnimation animateIn="fadeIn">
      <div className="services-div" id="services">
        <div className="img-parallax"></div>
        <h2>{appCtx.data.header.nav.services[appCtx.lang]}</h2>
        <div className="services-list">
          <div className="services-holder">
            <CriminalLaw />
            <LabourLaw />
            <CommercialLaw />
            <TortLaw />
            <InternationalLaw />
            <AdministrativeLaw />
            <CivilLaw />
          </div>
        </div>
        <div className="img-parallax second-parallax"></div>
      </div>
    </ScrollAnimation>
  );
};

export default Services;
