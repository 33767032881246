import { useState } from "react";

export const useBurger = () => {
  const [top, setTop] = useState("");
  const [middle, setMiddle] = useState("");
  const [bottom, setBottom] = useState("");

  const BurgerReset = () => {
    setTop("");
    setMiddle("");
    setBottom("");
  };

  const BurgerAnimate = () => {
    setTop("top-line");
    setMiddle("mid-line");
    setBottom("bottom-line");
  };

  return { top, middle, bottom, BurgerReset, BurgerAnimate };
};
