import React from "react";

import "./Service.css";

const Service = ({ service }) => {
  return (
    <div className="service">
      <h4>{service}</h4>
    </div>
  );
};

export default Service;
