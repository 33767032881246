import { useState } from "react";

export const useLanguage = () => {
  const [sr, setSr] = useState("activeLanguage");
  const [en, setEn] = useState("");
  const [srButton, setSrButton] = useState("SR");
  const [enButton, setEnButton] = useState("EN");
  const [lang, setLang] = useState(0);

  const changeLanguageHandler = (value) => {
    if (value === "EN") {
      setEn("activeLanguage");
      setSr("");
      setSrButton("SR");
      setLang(1);
    } else {
      setSr("activeLanguage");
      setEn("");
      setEnButton("EN");
      setLang(0);
    }
  };

  return {
    sr,
    en,
    lang,
    srButton,
    enButton,
    changeLanguageHandler,
  };
};
