import { createContext } from "react";

export const AppContext = createContext({
  top: "",
  middle: "",
  bottom: "",
  sr: "",
  en: "",
  select: "",
  lang: 0,
  data: {},
  loading: true,
  changeLanguageHandler: () => {},
  selectLanguageHandler: () => {},
  BurgerReset: () => {},
  BurgerAnimate: () => {},
});
