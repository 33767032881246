import React, { useContext } from "react";

import { AppContext } from "../../util/app-context";

import "./Language.css";

const Language = ({ close, aside }) => {
  const appCtx = useContext(AppContext);

  const languageChangeHandler = (e) => {
    if (e.target.value === appCtx.srButton) {
      appCtx.changeLanguageHandler(appCtx.srButton);
      if (aside) {
        close();
      }
    } else {
      appCtx.changeLanguageHandler(appCtx.enButton);
      if (aside) {
        close();
      }
    }
  };

  return (
    <div className="languages">
      <div className="lang-buttons">
        <button
          onClick={(e) => languageChangeHandler(e)}
          className={appCtx.sr}
          value={appCtx.srButton}
        >
          {appCtx.srButton}
        </button>
        <span></span>
        <button
          onClick={(e) => languageChangeHandler(e)}
          className={appCtx.en}
          id="eng-button"
          value={appCtx.enButton}
        >
          {appCtx.enButton}
        </button>
      </div>
    </div>
  );
};

export default Language;
