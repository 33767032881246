import React from "react";
import { Transition } from "react-spring/renderprops";

import "./Notification.css";

const Notification = ({ danger, msg }) => {
  return (
    <Transition
      items={msg}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      trail={300}
    >
      {(msg) =>
        msg &&
        ((props) => (
          <span style={props} className={danger ? "danger" : "success"}>
            {danger ? (
              <i className="fas fa-exclamation-triangle"></i>
            ) : (
              <i className="fa fa-check-square-o" aria-hidden="true"></i>
            )}{" "}
            {msg}
          </span>
        ))
      }
    </Transition>
  );
};

export default Notification;
