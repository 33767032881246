import React from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-spring/renderprops";

import "./Aside.css";

const Aside = ({ aside, children }) => {
  const content = (
    <Transition
      items={aside}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      trail={250}
    >
      {(aside) =>
        aside &&
        ((props) => (
          <div style={props}>
            <aside className="aside">{children}</aside>
          </div>
        ))
      }
    </Transition>
  );
  return ReactDOM.createPortal(
    content,
    document.getElementById("aside-drawer")
  );
};

export default Aside;
