import { useState, useEffect } from "react";

export const useData = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getData = () => {
    fetch("./languages.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (!res) {
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        setData(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return { data, loading };
};
