import React, { useContext, useState } from "react";
import axios from "axios";

import Notification from "../Notification/Notification";
import Info from "./Info";
import { messageHandler } from "../../util/message-handler";
import { AppContext } from "../../util/app-context";

const Form = () => {
  const appCtx = useContext(AppContext);
  const [name, setName] = useState("");
  const [sender, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState("");
  const [nameMsg, setNameMsg] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const [noteMsg, setNoteMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const clearForm = () => {
    setName("");
    setEmail("");
    setNote("");
  };

  const sendMailHandler = (e) => {
    e.preventDefault();

    if (!!!name && !!!sender && !!!note) {
      let msg =
        appCtx.lang === 0
          ? "Ime, email i/ili poruka nedostaju"
          : "Name, email and/or message are missing";
      messageHandler(setErrors, msg, clearForm);
      return;
    }
    if (!!!name) {
      let msg = appCtx.lang === 0 ? "Ime nije uneto!" : "Name is missing!";
      messageHandler(setNameMsg, msg, clearForm);
      return;
    }
    if (!!!sender) {
      let msg = appCtx.lang === 0 ? "Email nije unet!" : "Email is missing!";
      messageHandler(setEmailMsg, msg, clearForm);
      return;
    }
    if (!!!note) {
      let msg =
        appCtx.lang === 0 ? "Poruka nije uneta!" : "Message is missing!";
      messageHandler(setNoteMsg, msg, clearForm);
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(sender)) {
      let msg =
        appCtx.lang === 0
          ? "Email je pogrešnog formata!"
          : "Email is not valid!";
      messageHandler(setEmailMsg, msg, clearForm);
      return;
    }
    if (note.length <= 5) {
      let msg =
        appCtx.lang === 0
          ? "Poruka mora imati šest ili više karaktera!"
          : "Message must containt six or more characters!";
      messageHandler(setNoteMsg, msg, clearForm);
      return;
    }

    axios
      .post(
        "https://email-helper.herokuapp.com/",
        {
          sender: sender,
          target: "lawofficemilic@gmail.com",
          note: note,
          name: name,
          app: "Poslato sa miliclaw.com",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let msg = appCtx.lang === 0 ? res.data.sr : res.data.en;
        messageHandler(setSuccessMsg, msg, clearForm);
      })
      .catch((err) => {
        console.log(err);
        messageHandler(setErrors, err.response.data.message, clearForm);
      });
  };

  return (
    <div className="info-form-div">
      <Info />
      <div className="form-div">
        <form onSubmit={sendMailHandler}>
          <label htmlFor="">
            <i className="fal fa-signature"></i>{" "}
            {appCtx.data.contact.form.nameLabel[appCtx.lang]}{" "}
            <span>({appCtx.data.contact.form.mandatory[appCtx.lang]})</span>
          </label>
          <input
            type="text"
            placeholder={appCtx.data.contact.form.name[appCtx.lang]}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="errors-div">
            <Notification msg={nameMsg} danger={true} />
          </div>
          <label htmlFor="">
            <i className="fal fa-envelope"></i>{" "}
            {appCtx.data.contact.form.emailLabel[appCtx.lang]}{" "}
            <span>({appCtx.data.contact.form.mandatory[appCtx.lang]})</span>
          </label>
          <input
            type="email"
            name=""
            placeholder="E-mail"
            value={sender}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="errors-div">
            <Notification msg={emailMsg} danger={true} />
          </div>
          <label htmlFor="">
            <i className="fal fa-sticky-note"></i>{" "}
            {appCtx.data.contact.form.messageLabel[appCtx.lang]}{" "}
            <span>({appCtx.data.contact.form.mandatory[appCtx.lang]})</span>
          </label>
          <textarea
            name=""
            cols="30"
            rows="10"
            placeholder={appCtx.data.contact.form.message[appCtx.lang]}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
          <div className="errors-div">
            <Notification msg={noteMsg} danger={true} />
          </div>
          <div className="button-holder">
            <button>
              <span>
                {appCtx.data.contact.form.btn[appCtx.lang]}{" "}
                <i className="fas fa-envelope"></i>
              </span>
            </button>
          </div>
          <div className="errors-div">
            <Notification msg={errors} danger={true} />
          </div>
          <div className="errors-div" style={{ textAlign: "center" }}>
            <Notification msg={successMsg} danger={false} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
