import React, { useContext } from "react";

import NavLinks from "./NavLinks";
import Language from "../Language/Language";
import { AppContext } from "../../util/app-context";

import "./Header.css";

const Header = ({ open, aside, close, active }) => {
  const appCtx = useContext(AppContext);

  const reloadPage = () => {
    window.location.reload();
  };

  const burgerHandler = () => {
    if (!aside) {
      open();
      appCtx.BurgerAnimate();
      document.body.setAttribute("class", "remove-scroll");
    } else {
      close();
      appCtx.BurgerReset();
      document.body.setAttribute("class", "");
    }
  };
  return (
    <header>
      <div className="logo-div" onClick={reloadPage}>
        <h1>
          {appCtx.data.header.title.h1} <i className="fal fa-balance-scale"></i>
        </h1>
      </div>
      <div className="navbar">
        <button className="burger-btn" onClick={burgerHandler}>
          <p className={appCtx.top}></p>
          <p className={appCtx.middle}></p>
          <p className={appCtx.bottom}></p>
        </button>
        <NavLinks active={active} />
        <Language />
      </div>
    </header>
  );
};

export default Header;
