import React from "react";

import "./Loader.css";

const Loader = () => (
  <div className="loader-parent">
    <div className="loader"></div>
    <p className="wait">Molimo sačekajte / Please wait</p>
  </div>
);

export default Loader;
