import React, { useState } from "react";
import { css } from "emotion";

import "./ToTopButton.css";

const ToTopButton = () => {
  const [bottom, setBottom] = useState("5%");
  const [right, setRight] = useState("-120px");

  const style = css`
    bottom: ${bottom};
    right: ${right};
  `;

  let footer = document.querySelector("footer");
  let contact = document.querySelector("#contact");

  window.onscroll = () => {
    if (window.scrollY > 750) {
      if (footer) {
        let btnBottom;
        if (
          window.scrollY >
          document.body.scrollHeight -
            (footer.offsetHeight + contact.offsetHeight + 200)
        ) {
          btnBottom = (footer.offsetHeight + 1).toString() + "px";
          setRight("40px");
          setBottom(btnBottom);
        } else {
          setBottom("5%");
          setRight("20px");
        }
      }
    } else {
      setRight("-120px");
    }
  };

  return (
    <span
      id="to-top-btn"
      className={style}
      onClick={() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }}
    >
      <i className="fas fa-chevron-up" id="up-arrow"></i>
    </span>
  );
};

export default ToTopButton;
