import React from "react";

import "./SubService.css";

const SubService = ({ name }) => {
  return (
    <p className="subservice">
      <i className="fal fa-dot-circle"></i>
      {name}
    </p>
  );
};

export default SubService;
