import React, { useContext } from "react";
import { Link } from "react-scroll";

import { AppContext } from "../../util/app-context";

import "./NavLinks.css";

const NavLinks = ({ close, active }) => {
  const appCtx = useContext(AppContext);
  return (
    <div className="non-mobile-div">
      <span className={active === "services" ? "activeNavlink" : ""}>
        <Link
          to="services"
          spy={true}
          smooth={true}
          duration={300}
          onClick={close}
        >
          {appCtx.data.header.nav.services[appCtx.lang]}
        </Link>
      </span>
      <span className={active === "bio" ? "activeNavlink" : ""}>
        <Link to="bio" spy={true} smooth={true} duration={300} onClick={close}>
          {appCtx.data.header.nav.bio[appCtx.lang]}
        </Link>
      </span>
      <span className={active === "contact" ? "activeNavlink" : ""}>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          duration={300}
          onClick={close}
        >
          {appCtx.data.header.nav.contact[appCtx.lang]}
        </Link>
      </span>
      <span className="email-navlink">
        <a
          href="mailto:lawofficemilic@gmail.com"
          className="mailer"
          title="E-mail us!"
        >
          <i className="fal fa-envelope"></i>
        </a>
        <a
          href="https://www.google.com/maps/place/Advokatska+kancelarija+Mili%C4%87/@44.9714362,19.6156236,15z/data=!4m5!3m4!1s0x0:0xa10d487916633e68!8m2!3d44.9714362!4d19.6156236"
          target="_blank"
          rel="noopener noreferrer"
          className="mailer"
          style={{ marginLeft: "50px" }}
          title="Check us out on the map!"
        >
          <i className="fal fa-map"></i>
        </a>
      </span>
    </div>
  );
};

export default NavLinks;
